.bodyMain {
  width: 100vw;
  max-width: 1440px;
  padding-top: 8vh;
  overflow-y: scroll;
  font-family: Poppins;
}
@media (min-width: 1440px) {
  .bodyMain {
    margin-left: auto;
    margin-right: auto;
  }
}
.marketingHeader {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #02245A;
}
.sectionHeader {
  width: 100vw;
  max-width: 1440px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
@media (min-width: 1440px) {
  .sectionHeader {
    margin-left: auto;
    margin-right: auto;
  }
}
.callButtonSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.callButton  {
  width: 143px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #B3001B;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  font-size: 12px !important;
}
.callButtonIcon {
  height: 20px;
  padding-right: 10px;
}
.scrollableRow {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.scrollableRow::-webkit-scrollbar {
  width: 0;
}
.scrollableRow::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.marketingImage {
  height: 250px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  margin-top: 10px;
}
.servicesAnchor {
  display: block;
  position: relative;
  top: -6vh;
  visibility: hidden;
}
.servicesDesktopView {
  display: flex;
}
.servicesDesktop1 {
  flex: 1;
}
.servicesDesktop2 {
  flex: 1;
}
.serviceCardRow{
  display: flex;
  align-items: center;
  justify-content: center;
}
.serviceContainerMobile {
  min-height: 250px;
  min-width: 155px;
  border: 1px solid #3F5881;
  border-radius: 10px;
  margin: 5px;
  text-align: center;
  font-size: 12px;
}
.serviceContainer {
  height: 160px;
  width: 160px;
  border: 1px solid #3F5881;
  border-radius: 10px;
  margin: 7px;
  text-align: center;
  font-size: 12px;
  transform-style: preserve-3d;
  transition: 300ms;
  transform: rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
  z-index: 0;
}
.serviceContainer:hover {
  box-shadow: 0 0 5px 2px #3F5881;
}
.serviceContainer.flip {
  --rotate-y: 180deg;
}
.back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
}
.serviceIcon {
  height: 70px;
  padding: 20px;
}
.serviceTextHeaderMobile {
  font-weight: 600;
}
.serviceTextHeader {
  margin-top: 20px;
  font-weight: 600;
}
.serviceTextHeaderBack {
  margin-top: 20px;
  font-weight: 600;
  transform: rotateY(180deg);
}
.serviceAreaSection {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 200;
}
.serviceAreaTextSection {
  flex: 1;
  padding-left: 5vw;
  padding-right: 5vw;
}
@media (min-width: 1440px) {
  .serviceAreaTextSection {
    flex: 1;
    padding-left: 72px;
    padding-right: 72px;
  }
}
.serviceAreaHeader {
  text-align: left;
  padding-bottom: 25px;
}
.serviceAreaLine {
  text-align: left;
}
.serviceAreaDisclaimer {
  text-align: left;
  padding-top: 30px;
}
.serviceAreaImageSection {
  flex: 1;
  padding-right: 5vw;
}
.serviceAreaImage {
  max-width: 50vw;
  overflow: hidden;
}
@media (min-width: 1440px) {
  .serviceAreaImageSection {
    flex: 1;
    padding-right: 72px;
  }
  .serviceAreaImage {
    max-width: 360px;
  }
}
.contactAboutDesktopView {
  display: flex;
}
.aboutDesktopSectionHeader {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.aboutSection {
  display: flex;
  padding-bottom: 20px;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
}
@media (max-width: 699px) {
  .aboutPaul {
    flex: .45;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}
@media (min-width: 700px) {
  .aboutPaul {
    flex: .45;
    margin-left: 20vw;
  }
}
@media (min-width: 1440px) {
  .aboutPaul {
    flex: .45;
    margin-left: 72px;
    margin-right: 72px;
  }
}
.aboutPaulImage {
  height: 152px;
  box-shadow: -10px -10px 0px #3F5881;
}
.aboutPaulText {
  font-size: 10px;
  font-style: italic;
}
.aboutText {
  flex: 1;
  padding-right: 20px;
}
.aboutTextLine {
  padding-bottom: 10px;
}
.contactSection {
  text-align: center;
}
.contactDesktopSectionHeader {
  width: 600px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.contactLine {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.contactLineImage {
  padding-right: 15px;
}
.contactLineText {
  text-decoration: none !important;
  font-size: 12px !important;
  color: #02245A !important;
}
.socialButtons {
  padding-top: 10px;
}
.socialButton {
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
  cursor: pointer;
}
.copyrightSection {
  height: 45px;
  font-size: 10px;
  text-align: center;
  font-family: Helvetica;
  padding-top: 10px;
}
.blueBackground {
  background-color: #02245A;
}
.redBackground {
  background-color: #B3001B;
}
.greyBackground {
  background-color: #F6F6F6;
}
.blueGreyBackground {
  background-color: #E4E8F0;
}
.whiteBackground {
  background-color: #FFFFFF;
}
.blueText {
  color: #02245A;
}
.redText {
  color: #B3001B;
}
.greyText {
  color: #F6F6F6;
}
.whiteText {
  color: #FFFFFF;
}

@media (max-width: 699px) {
  .tabletView {
    display: none;
  }
  .desktopView { 
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 1439px) {
  .mobileView {
    display: none;
  }
  .desktopView { 
    display: none;
  }
}

@media (min-width: 1440px) {
  .mobileView {
    display: none;
  }
  .tabletView { 
    display: none;
  }
}
nav.navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .topNavMain {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.75);
    height: 3vh;
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
  }
  .menuOpen {
    background-color: rgba(255, 255, 255, 0.95);
  }
  .cccNavLogo {
    height: 20px;
    margin-left: 15px;
  }
  @media (min-width: 700px) {
    .cccNavLogo {
      height: 28px;
    }
  }
  .cccBrandName {
    flex: 1;
    display: flex;
    align-items: left;
    justify-content: left;
  }
  .brandNameText {
    height: 34px;
    margin-left: 10px;
  }
  .navPhoneIcon {
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }
  .navHamburgerIcon {
    height: 12px;
    margin-left: 10px;
    margin-right: 15px;
  }
  .navDropdownTab {
    background-color: #02245A;
    opacity: 90%;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navDropdownMenu {
    position: fixed;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.95);
    margin-top: 26vh;
    width: 100vw;
    height: 20vh;
  }
  .navMenuItem {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #02245A;
    font-family: Poppins;
    display: block;
    text-align: right;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  .navMenuItem:hover,
  .navMenuItem:active {
    background-color: #02245a;
    color: #F1F2F6;
  }
  .navMenuItem:focus {
    outline: none;
    color: #F1F2F6;
  }
  .navDropdownBackground {
    position: fixed;
    margin-top: 146vh;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(30px);
    background-color: rgba(2,36,90, .75);
  }

  .navItem {
    margin-right: 15px;
    color: #02245A;
    font-family: Poppins;
    display: block;
    text-align: right;
    text-decoration: none !important;
    font-size: 12px !important;
  }
  .navCallButton {
    width: 100px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #B3001B;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    font-size: 12px !important;
  }

  @media (max-width: 699px) {
    .nonMobileNavView {
      display: none;
    }
  }
  @media (min-width: 700px) and (max-width: 1439px) {
    .mobileNavView {
      display: none;
    }
    .desk {
      display: none;
    }
  }
  @media (min-width: 1440px) {
    .mobileNavView {
      display: none;
    }
    .tab {
      display: none;
    }
  }